import React from 'react';
import {
  LoginFormTitle,
  LoginFormWrapper,
  SubmitBtn,
  StyledAuthInput,
  StyledAuthFormItem,
  PasswordLabelWrapper,
  ForgotFormWrapper,
  ErrorLine,
  ErrorText,
} from './styles';
import {Form} from 'antd';
import {useLoginRes} from '../../hooks/auth';
import {Link} from 'react-router-dom';
import {EmailResultLine, ResultText} from '../Forgot/styles';

type LoginFormProps = {
  authData: useLoginRes;
  successUpdated?: boolean;
};

const validateMessages = {
  types: {
    email: 'Invalid email. Please enter a valid email.',
    password: 'Invalid password. Please enter a valid password.',
  },
};

const LoginFormForgot: React.FC = () => {
  return <Link to="/forgot-password">Forgot password</Link>;
};

export const PasswordLabel: React.FC = () => {
  return (
    <>
      <span>Password</span>
      <ForgotFormWrapper>
        <LoginFormForgot />
      </ForgotFormWrapper>
    </>
  );
};

export const LoginForm: React.FC<LoginFormProps> = ({authData, successUpdated}) => {
  const {onSubmit, error, resetError} = authData;
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    onSubmit(values);
  };
  return (
    <LoginFormWrapper
      form={form}
      onFinish={onFinish}
      onChange={resetError}
      validateMessages={validateMessages}
      layout={'vertical'}>
      <LoginFormTitle>Admin Login</LoginFormTitle>
      <StyledAuthFormItem
        label={'Email Address'}
        name={['email']}
        rules={[
          {required: true, message: 'Email Address is required!'},
          {
            type: 'email',
          },
        ]}>
        <StyledAuthInput placeholder="Enter email" />
      </StyledAuthFormItem>
      <StyledAuthFormItem
        label={
          <PasswordLabelWrapper>
            <PasswordLabel />
          </PasswordLabelWrapper>
        }
        rules={[{required: true, message: 'Password is required!'}]}
        name={['password']}>
        <StyledAuthInput type={'password'} placeholder="Enter password" />
      </StyledAuthFormItem>
      {error && (
        <ErrorLine>
          <ErrorText>{error}</ErrorText>
        </ErrorLine>
      )}
      <StyledAuthFormItem>
        <SubmitBtn htmlType="submit" loading={authData.loading}>
          Sign In
        </SubmitBtn>
      </StyledAuthFormItem>
      {successUpdated && (
        <EmailResultLine>
          <ResultText>Success, your password has been updated.</ResultText>
        </EmailResultLine>
      )}
    </LoginFormWrapper>
  );
};
