import React from 'react';
import {FooterText, FooterWrapper, ReshareAccessButton, FooterCenterText} from './styles';

export type FooterPropsT = {
  text?: string;
  showReshare?: boolean;
  buttonText?: string;
  onReshare?: () => void;
};

export const Footer = (props: FooterPropsT) => {
  const {text, showReshare, buttonText, onReshare} = props;
  const baseText = `© Enchant Labs ${new Date().getFullYear()}.`;

  return (
    <FooterWrapper {...props}>
      {showReshare ? (
        <>
          <FooterText>{text || baseText}</FooterText>
          <ReshareAccessButton onClick={onReshare}>{buttonText}</ReshareAccessButton>
        </>
      ) : (
        <FooterCenterText>{text || baseText}</FooterCenterText>
      )}
    </FooterWrapper>
  );
};
