import styled, {css} from 'styled-components';
import {Form, Input as AntInput, InputNumber as AntInputNumber, Typography} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';

const {Item} = Form;
const {TextArea: AntTextArea, Search: AntSearch} = AntInput;
const {Text} = Typography;

export const StyledForm = styled(Form)``;

export const FormItem = styled(Item)`
  && {
    position: relative;
    margin-bottom: 22px;
  }
`;

const inputStyle = css`
  color: ${({theme}) => theme.palette.text.primary};
  outline: none;
  padding: 5px 12px;
  box-sizing: border-box;
  border-radius: 5px;
  :active {
    border: 2px solid ${({theme}) => theme.palette.common.brown};
  }
  :focus {
    border: 2px solid ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
  &:hover {
    border-color: ${({theme}) => theme.palette.common.brown};
  }
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${({theme}) => theme.palette.common.brown};
  }
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    box-shadow: none;
  }
`;

export const Input = styled(AntInput)`
  height: 38px;
  ${inputStyle}
`;

export const InputNumber = styled(AntInputNumber)`
  &.ant-input-number:hover,
  &.ant-input-number:focus {
    border-color: ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
  &.ant-input-number .ant-input-number-input {
    width: 30px;
    height: 24px;
    text-align: center;
    padding: 0 5px;
  }
  .ant-input-number-group-addon {
    padding: 0 8px;
    background-color: ${({theme}) => theme.palette.common.systemGray};
  }
  .ant-input-number-group-addon button {
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }
`;

export const TextArea = styled(AntTextArea)`
  ${inputStyle}
`;

export const SearchInput = styled(AntSearch)`
  ${inputStyle}
  padding: 0;
  :active,
  :focus {
    border: none;
  }

  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 8px;
  }

  .ant-input-group > .ant-input:first-child,
  &.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 4px;
    min-width: 300px;
    height: 36px;
    :active {
      border: 2px solid ${({theme}) => theme.palette.common.brown};
    }
    :focus {
      border: 2px solid ${({theme}) => theme.palette.common.brown};
      box-shadow: none;
    }
    :hover {
      border-color: ${({theme}) => theme.palette.common.brown};
    }
  }
  &.ant-input-search .ant-input-group .ant-input-affix-wrapper-focused {
    border: 2px solid ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }

  &.ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    background-color: ${({theme}) => theme.palette.common.brown};
    width: 129px;
    height: 36px;
    border-radius: 40px;
    :active,
    :focus,
    :hover {
      border: 1px solid ${({theme}) => theme.palette.common.brown};
    }
    span > svg {
      display: none;
    }
    span::after {
      content: 'Search';
      color: ${({theme}) => theme.palette.common.white};
      font-size: 16px;
      line-height: 19px;
      font-family: 'Proxima Nova';
    }
  }

  &.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: ${({theme}) => theme.palette.common.systemGray};
  }
`;

export const UploadIconRow = styled.div.attrs({
  className: 'ant-upload-drag-icon',
})`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const UploadIcon = styled(UploadOutlined)<{$woTheme?: boolean}>`
  color: ${({theme, $woTheme}) => ($woTheme ? `#937E6B` : theme.palette.common.brown)};
  font-size: 40px;
`;

export const UploadTitle = styled(Text)<{$woTheme?: boolean}>`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  display: block;
  margin-bottom: 8px;
  font-weight: ${({theme, $woTheme}) => ($woTheme ? 700 : theme.font.weight.bold)};
`;

export const UploadDescr = styled(Text).attrs({
  type: 'secondary',
})<{$woTheme?: boolean}>`
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  display: block;
`;

export const StyledDragger = styled(Dragger)<{$woTheme?: boolean}>`
  padding-left: 28px;
  padding-right: 28px;
  box-sizing: border-box;
  && {
    border: 1px solid ${({theme, $woTheme}) => ($woTheme ? `#D9D9D9` : theme.palette.border.darkGray)};
    border-radius: 5px;
  }
  &.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: ${({theme, $woTheme}) => ($woTheme ? `#937E6B` : theme.palette.common.brown)};
  }
`;

export const ResultWrapper = styled.div<{$woTheme?: boolean}>`
  padding-left: 28px;
  padding-right: 28px;
  box-sizing: border-box;
  border: 1px solid ${({theme, $woTheme}) => ($woTheme ? `#D9D9D9` : theme.palette.border.darkGray)};
  border-radius: 5px;
  cursor: pointer;
  min-height: 162px;
  display: flex;
  justify-content: center;
  align-items: center;
  & .ant-result {
    padding: 28px 0;
  }
  & .ant-result-title {
    font-size: 16px;
  }
  & .ant-result-icon > .anticon {
    font-size: 42px;
  }
`;
