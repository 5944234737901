import React from 'react';
import {AppLayout} from '../ui-kit/AppLayout';
import {WithChildren} from '../types/helpers';
import styled from 'styled-components';

const StyledAppLayout = styled(AppLayout)`
  background-color: ${({theme}) => theme.palette.background.gray};
  & main {
    margin-bottom: 0;
  }
`;

export const Layout: React.FC<WithChildren> = ({children}) => {
  return (
    <StyledAppLayout header={null} footer={null}>
      {children}
    </StyledAppLayout>
  );
};
